import React from 'react';
import { useAuth } from '../auth/context/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY, PRODUCT_PRICES } from '../config/stripe.js';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PricingPage = () => {
  const { user } = useAuth();

  const handleSubscribe = async (priceId) => {
    try {
      const stripe = await stripePromise;

      // Criar sessão de checkout no servidor
      const response = await fetch('https://frenchaudiochat.com/api/stripe/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user?.token}`
        },
        body: JSON.stringify({
          priceId,
          userId: user?.uid,
        }),
      });

      const session = await response.json();

      // Redirecionar para o checkout do Stripe
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const plans = [
    {
      name: 'Basic Plan',
      price: 'R$ 29',
      credits: 200,
      priceId: PRODUCT_PRICES.basic,
      features: [
        '200 interactions',
        'Corrections détaillées',
        'Support prioritaire'
      ]
    },
    {
      name: 'Pro Plan',
      price: 'R$ 49',
      credits: 500,
      priceId: PRODUCT_PRICES.pro,
      features: [
        '500 interactions',
        'Corrections détaillées',
        'Support prioritaire',
        'Statistiques avancées'
      ],
      popular: true
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a237e] via-[#0d47a1] to-[#01579b] py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white sm:text-4xl">
            Choisissez votre plan
          </h2>
          <p className="mt-3 text-xl text-blue-100">
            Continuez votre apprentissage du français avec notre assistant IA
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 xl:max-w-4xl xl:mx-auto">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`rounded-lg shadow-lg divide-y divide-gray-200 bg-white ${
                plan.popular ? 'border-2 border-blue-500' : ''
              }`}
            >
              <div className="p-6">
                {plan.popular && (
                  <span className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 text-blue-600 mb-4">
                    Plus populaire
                  </span>
                )}
                <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">{plan.price}</span>
                </p>
                <p className="mt-4 text-gray-500">
                  {plan.credits} crédits
                </p>
                <button
                  onClick={() => handleSubscribe(plan.priceId)}
                  className={`mt-8 block w-full py-3 px-6 rounded-md text-white text-center font-medium ${
                    plan.popular
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  Acheter maintenant
                </button>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h4 className="text-sm font-medium text-gray-900 tracking-wide uppercase">
                  Inclus dans ce plan
                </h4>
                <ul className="mt-4 space-y-3">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-gray-500">{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
