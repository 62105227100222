import { db } from '../config/firebase';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';

export const userTrackingService = {
  async getUserInfo(userId) {
    if (!userId) return null;

    const userRef = doc(db, 'usuarios', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      const newUser = {
        freeAttemptsUsed: 0,
        freeAttemptsLimit: 2,
        isPremium: false,
        credits: 0,
        totalCreditsUsed: 0,
        stripeCustomerId: "",
        lastUsed: new Date(),
        currentPlan: {
          name: "basic",
          purchaseDate: new Date(),
          creditsTotal: 0
        }
      };
      await setDoc(userRef, newUser);
      return newUser;
    }

    return userDoc.data();
  },

  async checkUserAttempts(userId) {
    if (!userId) return { canUse: false, message: 'Utilisateur non connecté' };

    const userInfo = await this.getUserInfo(userId);

    if (userInfo.isPremium && userInfo.credits > 0) {
      return { canUse: true, credits: userInfo.credits };
    }

    if (!userInfo.isPremium && userInfo.freeAttemptsUsed < userInfo.freeAttemptsLimit) {
      return {
        canUse: true,
        freeAttemptsLeft: userInfo.freeAttemptsLimit - userInfo.freeAttemptsUsed
      };
    }

    return {
      canUse: false,
      message: userInfo.isPremium
        ? 'Vos crédits sont épuisés. Veuillez recharger votre compte.'
        : 'Vos essais gratuits sont épuisés. Passez à la version premium.'
    };
  },

  async decrementCredits(userId) {
    const userRef = doc(db, 'usuarios', userId);
    const userInfo = await this.getUserInfo(userId);

    if (userInfo.isPremium) {
      await updateDoc(userRef, {
        credits: increment(-1),
        totalCreditsUsed: increment(1),
        lastUsed: new Date()
      });
    } else {
      await updateDoc(userRef, {
        freeAttemptsUsed: increment(1),
        lastUsed: new Date()
      });
    }
  }
};

export default userTrackingService;
