import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDgMjAwXMZHDH5DhtfMQwLd8uuJnk78BRo",
  authDomain: "frenchaudiochat-1cf8c.firebaseapp.com",
  projectId: "frenchaudiochat-1cf8c",
  storageBucket: "frenchaudiochat-1cf8c.appspot.com",
  messagingSenderId: "108949819996",
  appId: "1:108949819996:web:c784fd817c2c602fa40c99",
  measurementId: "G-MT8YRR11RJ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export { app, analytics };
